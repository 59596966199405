import React, { useMemo } from "react";
import { RouteObject, useRoutes } from "helpers/tixxt-router";
import { flatMap } from "lodash";

import { UserbarContent } from "components/layout/Userbar";
import ActivitystreamsUserbar from "components/activitystreams/Userbar";
import MessagesUserbar from "components/messages/Userbar";
import AppointmentsUserbar from "components/appointments/Userbar";
import TasksUserbar from "components/tasks/Userbar";
import FilesUserbar from "components/files/Userbar";
import AdministrationUserbar from "components/administration/Userbar";
import InfoUserbar from "components/info/Userbar";
import SearchUserbar from "components/globalSearch/Userbar";
import MembershipsUserbar from "components/memberships/Userbar";
import DirectoryUserbar from "components/directory/Userbar";
import PagesUserbar from "components/pages/Userbar";
import AppCreatorUserbar from "components/appCreator/Userbar";
import ChannelsUserbar from "components/channels/Userbar";
import ChannelAdministrationUserbar from "components/administration/channels/Userbar";

/*
Expands the given rootPath into routes for network and group context as well as all subpaths (via wildcard)
 */
function expandRootPaths(
  rootPaths: string[],
  routeObject: Omit<RouteObject, "path">,
): RouteObject[] {
  return flatMap(rootPaths, (rootPath) => [
    { ...routeObject, path: rootPath + "/*" },
    { ...routeObject, path: "/groups/:groupSlug" + rootPath + "/*" },
  ]);
}

function moduleRoutes(): RouteObject[] {
  return [
    ...expandRootPaths(
      ["/activitystreams", "/activities", "/invites", "/notifications"],
      { element: <ActivitystreamsUserbar /> },
    ),
    ...expandRootPaths(["/messages"], { element: <MessagesUserbar /> }),
    ...expandRootPaths(["/appointments", "/calendars"], {
      element: <AppointmentsUserbar />,
    }),
    ...expandRootPaths(["/tasks"], { element: <TasksUserbar /> }),
    ...expandRootPaths(["/files"], { element: <FilesUserbar /> }),
  ];
}

function extraRoutes(): RouteObject[] {
  return [
    { path: "/info", element: <InfoUserbar /> },
    { path: "/imprint", element: <InfoUserbar /> },
    { path: "/search", element: <SearchUserbar /> },
    { path: "/groups/:groupSlug/search", element: <SearchUserbar /> },
    { path: "/administration/*", element: <AdministrationUserbar /> },
    {
      path: "/groups/:groupSlug/administration/*",
      element: <AdministrationUserbar />,
    },
    {
      path: "/group-categories/:groupCategorySlug/administration/*",
      element: <AdministrationUserbar />,
    },
    {
      path: "/groups/:groupSlug/invites/sent",
      element: <AdministrationUserbar />,
    },
    {
      path: "/groups/:groupSlug/membership_invites/new",
      element: <DirectoryUserbar />,
    },
    {
      path: "/channels/:channelSlug/administration",
      element: <ChannelAdministrationUserbar />,
    },
    {
      path: "/channels/:channelSlug/edit",
      element: <ChannelAdministrationUserbar />,
    },
    {
      path: "/channels/:channelSlug/layout/edit",
      element: <ChannelAdministrationUserbar />,
    },
    {
      path: "/channels/:channelSlug/design/edit",
      element: <ChannelAdministrationUserbar />,
    },
    {
      path: "/channels/:channelSlug/widgets",
      element: <ChannelAdministrationUserbar />,
    },
    {
      path: "/channels/:channelSlug/widgets/*",
      element: <ChannelAdministrationUserbar />,
    },

    { path: "/groups/:groupSlug/edit", element: <AdministrationUserbar /> },
    { path: "/invites/sent", element: <AdministrationUserbar /> },
    { path: "/invites/new", element: <AdministrationUserbar /> },
    { path: "/groups/:groupSlug/members", element: <DirectoryUserbar /> },
    { path: "/directory/:sectionSlug", element: <DirectoryUserbar /> },
    { path: "/members", element: <DirectoryUserbar /> },
    { path: "/map", element: <DirectoryUserbar map /> },
    { path: "/map/members", element: <DirectoryUserbar map /> },
    { path: "/groups/:groupSlug/map", element: <DirectoryUserbar map /> },
    { path: "/groups/:groupSlug", element: <ActivitystreamsUserbar /> },
    { path: "/pages/:pageSlug", element: <PagesUserbar /> },
    { path: "/pages/:pageSlug/*", element: <PagesUserbar /> },
    { path: "/apps/:appSlug", element: <AppCreatorUserbar /> },
    { path: "/apps/:appSlug/*", element: <AppCreatorUserbar /> },
    {
      path: "/groups/:groupSlug/pages/:pageSlug",
      element: <DirectoryUserbar />,
    },
    { path: "/members/:membershipId", element: <MembershipsUserbar /> },
    { path: "/members/:membershipId/*", element: <MembershipsUserbar /> },
    { path: "/users/edit", element: <MembershipsUserbar /> },
    { path: "/channels", element: <ChannelsUserbar /> },
    { path: "/channels/:channelSlug", element: <ChannelsUserbar /> },
    { path: "/channels/:channelSlug/*", element: <ChannelsUserbar /> },
    {
      path: "/blogs",
      element: <ChannelsUserbar />,
    },
    {
      path: "/blogs/:blogId/articles/:articleId",
      element: <ChannelsUserbar />,
    },
    {
      path: "/blogs/:blogId/articles/:articleId/*",
      element: <ChannelsUserbar />,
    },
    {
      path: "/blogs/:blogId/*",
      element: <ChannelsUserbar />,
    },
    { path: "/users/sign_in", element: null },
    { path: "/users/password/new", element: null },
    { path: "/*", element: <InfoUserbar /> },
  ];
}

/*
This component is nothing more than routes, some of which are conditional, to the individual Userbars
 */
export default function TixxtUserbar() {
  const routes = useMemo(() => [...moduleRoutes(), ...extraRoutes()], []);
  const element = useRoutes(routes);

  return <UserbarContent id="userbar">{element}</UserbarContent>;
}
