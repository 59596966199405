import React from "react";
import PageTitle from "components/layout/PageTitle";
import { useParams } from "helpers/tixxt-router";
import ToggleChannelFavoriteButton from "components/shared/favoriteButtons/ToggleChannelFavoriteButton";
import { useChannel } from "components/channels/api";
import { useChannelLayout } from "components/channels/layout/api";
import { faGear } from "@fortawesome/pro-thin-svg-icons";
import DropDown, { DropDownItem } from "components/shared/DropDown";
import { ChannelLayout } from "./ChannelLayout";
import { isString } from "lodash";
import Hint from "components/shared/fields/Hint";

export default function ChannelPage({
  isAdministration = false,
}: {
  isAdministration?: boolean;
}) {
  const { channelSlug } = useParams();
  const { data: channel } = useChannel(channelSlug);
  const { data: layoutData, isLoading: layoutLoading } =
    useChannelLayout(channelSlug);
  const hasChangesString = sessionStorage.getItem(`${channelSlug}:hasChanges`);

  const hasChanges = isString(hasChangesString) && JSON.parse(hasChangesString);

  const dropdownItems: React.JSX.Element[] = [];
  if (channel?.i.can.update && !isAdministration)
    dropdownItems.push(
      <DropDownItem
        key="update"
        text={I18n.t("js.channels.title.dropdown.edit_channel")}
        url={`/channels/${channel.slug}/edit`}
      />,
      <DropDownItem
        key="edit_layout"
        text={I18n.t("js.channels.title.dropdown.edit_channel_layout")}
        url={`/channels/${channel.slug}/layout/edit`}
      />,
      <DropDownItem
        key="add_article"
        text={I18n.t("js.channels.title.dropdown.add_article")}
        url={`/blogs/${channel.blog_id}/articles/new`}
      />,
    );

  return (
    <>
      {(channel?.status === "draft" || channel?.status === "archived") &&
      !channel?.i.can.update ? (
        <p>{I18n.t(`js.channels.${channel.status}.body`)}</p>
      ) : (
        <>
          {hasChanges && (
            <div className="alert alert-info">
              <Hint
                hint={I18n.t("js.channels.layout.not_saved_hint")}
                className="!mb-0"
              />
            </div>
          )}
          <PageTitle title={channel?.title || ""}>
            {channel && !isAdministration && (
              <div className={"flex place-items-center"}>
                <ToggleChannelFavoriteButton
                  channelSlug={channel.slug}
                  favorited={channel.i.favorited}
                />
                {dropdownItems.length > 0 && (
                  <>
                    <DropDown.Toggle icon={faGear} buttonType="normal" small />
                    <DropDown alignment="start">{dropdownItems}</DropDown>
                  </>
                )}
              </div>
            )}
          </PageTitle>
          <div className="btn-toolbar mb-10 justify-end">
            <a href={`/blogs/${channel?.blog_id}`} className="btn btn-light">
              Blog anzeigen
            </a>
          </div>
          <ChannelLayout
            layoutData={layoutData}
            channelSlug={channel?.id}
            isLoading={layoutLoading}
          />
        </>
      )}
    </>
  );
}
